<template>
  <div class="next-same-events">
    <SidebarTitle :title="translate('next5Events', 5)"
                  :subtitle="translate('events')" />
    <div class="flag-wrapper">
      <FlagCategoryTournament :event="mainEvents[0]"
                              :flag-size="getFlagSize"/>
    </div>
    <Market :market="getMarket(mainEvents[0].markets, '832')"
            :secondary-name="translate('draw')"
            :sidebar="true"/>
    <h1>{{translate('schedule')}}</h1>
    <h2>{{translate('next')}}</h2>
    <EventIdTime :event-id="mainEvents[0].lotteryDisplayId"
                 :others="false"
                 :is-first="true"
                 :time="mainEvents[0].eventStartTimeUTC"/>
    <h2>{{translate('later')}}</h2>
    <EventIdTime v-for="(event, indexEvent) in getOtherEvents"
                 :key="event.eventId"
                 :is-first="indexEvent === 0"
                 :event-id="event.lotteryDisplayId"
                 :time="event.eventStartTimeUTC"/>
  </div>
</template>

<script>
import FlagCategoryTournament from '@/components/Retail/FlagCategoryTournament';
import { mapGetters } from 'vuex';
import Market from '@/components/Retail/Market';
import { searchMarket } from '@/utility';
import EventIdTime from '@/components/Retail/EventIdTime';
import { filter } from 'lodash';
import SidebarTitle from '@/components/Retail/SidebarTitle';
import switchComponent from '@/utility/switchComponent';

export default {
  name: 'NextSameEvents',
  components: {
    SidebarTitle, EventIdTime, Market, FlagCategoryTournament,
  },
  computed: {
    ...mapGetters([
      'mainEvents',
      'translate',
      'screenSize',
      'bettingOffer',
    ]),
    getOtherEvents() {
      return filter(this.mainEvents, (event, index) => index !== 0);
    },
    getFlagSize() {
      return {
        width: (this.screenSize === 1920 ? '105px' : '75px'),
        height: (this.screenSize === 1920 ? '75px' : '50px'),
      };
    },
  },
  methods: {
    getMarket(markets, betType) {
      return searchMarket(markets, betType);
    },
    closeComponent() {
      const number = this.bettingOffer(0) && this.bettingOffer(0).length > 0 ? 4 : 1;
      switchComponent(
        'right',
        number,
        this.$store.getters.transitionBetweenComponentDuration,
      );
    },
  },
  mounted() {
    this.closeComponent();
  },
};
</script>

<style lang="scss" scoped>
.next-same-events {
  height: 100%;
  overflow: hidden;
  > h1,
  > h2 {
    color: #EFEFEF;
    font-weight: 400;
    text-align: center;
  }
  .flag-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    @media (min-width: 1920px) {
      padding-top: 32px;
    }
  }
  > h1 {
    font-size: 24px;
    margin-top: 28px;
    @media (min-width: 1920px) {
      font-size: 36px;
      margin-top: 32px;
    }
  }
  > h2 {
    color: #A4A4A4;
    font-size: 16px;
    font-weight: 400;
    margin-top: 14px;
    text-transform: uppercase;
    @media (min-width: 1920px) {
      font-size: 24px;
      margin-top: 16px;
    }
  }
}
</style>
