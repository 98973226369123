<template>
  <div class="title">
    <div>{{ title }}</div>
    <div v-if="subtitle">
      <span>{{ subtitle }}</span>
      <RemainingTime v-if="haveTime" />
    </div>
  </div>
</template>

<script>
import RemainingTime from '@/components/Retail/RemainingTime';

export default {
  name: 'SidebarTitle',
  components: { RemainingTime },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    haveTime: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #EFEFEF;
  font-weight: 400;
  text-align: center;
  div {
    &:first-child {
      font-size: 24px;
      @media (min-width: 1920px) {
        font-size: 36px;
      }
    }
    &:nth-child(2) {
      font-size: 16px;
      @media (min-width: 1920px) {
        font-size: 24px;
      }
    }
  }
}
</style>
