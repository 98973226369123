<template>
  <div class="new-name">{{ getName }}</div>
</template>

<script>
import replace from 'lodash/replace';

export default {
  name: 'NewName',
  props: {
    name: {
      type: String,
      required: true,
    },
    searchedValue: {
      type: String,
      required: true,
    },
    newValue: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    getName() {
      return replace(this.name, this.searchedValue, this.newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.new-name {
  text-transform: lowercase;
  &:first-letter {
    text-transform: capitalize;
  }
}
</style>
