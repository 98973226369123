<template>
  <div class="market"
       :class="[
         `market-${market.betType}`,
         {'sidebar': sidebar, 'betting-offer-market': bettingOffer}]">
    <p v-if="!sidebar">
      {{ secondaryName ||  market.shortName }}
      <span v-if="bettingShortcuts">{{ market.shortcut }}</span>
    </p>
    <section class="outcomes"
             v-for="(outcomes, outcomesIndex) in getOutcomes"
             :key="outcomesIndex">
      <div class="outcomes-name"
           :class="[`column-${column}`,
           {'round-borders': outcomesRoundBorder, 'shortcuts': bettingShortcuts}]">
        <div class="outcome-name"
             :class="{'shortcuts': bettingShortcuts}"
             v-for="(value, index) in outcomes"
             :key="`name${index}`">
          <span>{{value.shortName}}</span>
        </div>
      </div>
      <div class="outcomes-odd-shortcut"
           :class="[`column-${column}`, {'round-borders': outcomesRoundBorder}]">
        <div class="outcome-name"
             v-for="(value, oddShortcutIndex) in outcomes"
             :key="`odd-shortcut${oddShortcutIndex}`">
          <span v-if="!bettingShortcuts">
            {{getOdd(value.odd)}}
          </span>
          <span v-else>
            {{value.shortcut}}
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import { chunk } from 'lodash';
import { getOdd } from '@/utility';

export default {
  name: 'Market',
  props: {
    market: {
      type: Object,
      required: true,
    },
    secondaryName: {
      type: String,
    },
    bettingShortcuts: {
      type: Boolean,
      default: false,
    },
    outcomesRoundBorder: {
      type: Boolean,
      default: true,
    },
    column: {
      type: Number,
      default: () => 8,
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
    bettingOffer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getOutcomes() {
      const outcomes = chunk(this.market.outcomes, this.column);
      return outcomes;
    },
  },
  methods: {
    getOdd(odd) {
      return getOdd(odd);
    },
  },
};
</script>

<style lang="scss" scoped>
.market {
  font-size: 16px;
  margin-top: 6px;
  text-transform: uppercase;
  @media (min-width: 1920px) {
    font-size: 24px;
  }
  &.sidebar {
    margin-top: 10px;
  }
  &.betting-offer-market {
    margin-top: 4px !important;
    @media (min-width: 1920px) {
      margin-top: 11px !important;
    }
    .outcomes {
      background: rgba(255, 255, 255, 0.52) !important;
      border-radius: 8px;
      grid-template-rows: 20px 17px !important;
      grid-row-gap: 0;
      padding: 5px 0 8px;
      @media (min-width: 1920px) {
        grid-template-rows: 25px 35px !important;
        padding: 8px 0 12px;
      }
      .outcomes-name,
      .outcomes-odd-shortcut {
        background: transparent !important;
        .outcome-name {
          > span {
            color: #363636;
          }
        }
      }
    }
  }
  &.market-821,
  &.market-822,
  &.market-823 {
    .outcomes {
      grid-template-rows: 36px 48px;
      @media (min-width: 1920px) {
        grid-template-rows: 53px 72px;
      }
      .outcomes-name {
        @media (min-width: 1920px) {
          font-size: 20px;
        }
        &.round-borders {
          background: rgba(79, 79, 79, 0.4);
          &.shortcuts {
            background: rgba(123, 165, 62, 0.4);
          }
        }
      }
      .outcomes-odd-shortcut {
        @media (min-width: 1920px) {
          font-size: 28px;
        }
        &.round-borders {
          background: rgba(255, 255, 255, 0.35);
        }
      }
    }
  }
  &.market-823 {
    @media (min-width: 1920px) {
      margin-top: 22px;
    }
  }
  &.market-832 {
    &.sidebar {
      @media (min-width: 1920px) {
        margin-top: 17px;
      }
      .outcomes {
        grid-template-rows: 36px 50px;
        @media (min-width: 1920px) {
          grid-template-rows: 50px 80px;
        }
      }
      .outcomes-name {
        font-size: 14px;
        @media (min-width: 1920px) {
          font-size: 18px;
        }
      }
      .outcomes-odd-shortcut {
        font-size: 16px;
        @media (min-width: 1920px) {
          font-size: 22px;
        }
      }
    }
    &:not(&.sidebar) {
      @media (min-width: 1920px) {
        margin-top: 23px;
      }
    }
    .outcomes {
      @media (min-width: 1920px) {
        grid-template-rows: 64px 96px;
      }
    }
    .outcomes-name {
      font-size: 18px;
      @media (min-width: 1920px) {
        font-size: 24px;
      }
      &.round-borders {
        background: rgba(11, 11, 11, 0.4);
      }
    }
    .outcomes-odd-shortcut {
      @media (min-width: 1920px) {
        font-size: 32px;
      }
      &.round-borders {
        background: rgba(255, 255, 255, 0.52);
      }
    }
  }
  &.market-821,
  &.market-822 {
    margin-top: 7px;
    @media (min-width: 1920px) {
      margin-top: 2px;
    }
    &.shortcuts {
      margin-top: 8px;
    }
    .outcomes-name {
      &:not(.round-borders) {
        grid-column-gap: 4px;
        @media (min-width: 1920px) {
          grid-column-gap: 8px;
        }
        .outcome-name {
          background: rgba(79, 79, 79, 0.4);
          border-radius: 8px;
          &.shortcuts {
            background: rgba(123, 165, 62, 0.4);
          }
        }
      }
    }
    .outcomes-odd-shortcut {
      &:not(.round-borders) {
        grid-column-gap: 4px;
        @media (min-width: 1920px) {
          grid-column-gap: 8px;
        }
        .outcome-name {
          background: rgba(255, 255, 255, 0.35);
          border-radius: 8px;
        }
      }
    }
  }
  > p {
    margin-bottom: 8px;
    > span:before {
      content: "-";
      padding-right: 3px;
    }
  }
  .outcomes {
    align-items: center;
    display: grid;
    grid-template-rows: 42px 64px;
    grid-row-gap: 4px;
    text-align: center;
    @media (min-width: 1920px) {
      grid-row-gap: 8px;
    }
    &:nth-child(3) {
      margin-top: 16px;
    }
    .outcomes-odd-shortcut,
    .outcomes-name {
      &.round-borders {
        border-radius: 8px;
      }
      display: grid;
      height: 100%;
      &.column-2 {
        grid-template-columns: repeat(2, calc(100% / 2));
      }
      &.column-7 {
        grid-template-columns: repeat(7, calc(100% / 7));
      }
      &.column-8 {
        grid-template-columns: repeat(8, calc(100% / 8));
      }
      .outcome-name {
        position: relative;
        > span {
          left: 50%;
          opacity: .88;
          position: absolute;
          text-transform: capitalize;
          top: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
        }
      }
    }
    .outcomes-odd-shortcut {
      font-weight: bold;
      font-size: 24px;
      .outcome-name {
        > span {
          color: #121212;
        }
      }
    }
  }
}
</style>
