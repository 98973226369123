import EventBus from '@/store/EventBus';

let timeoutInterval = null;
EventBus.$on('stopIntervalsAndTimeouts', () => {
  clearTimeout(timeoutInterval);
});
const switchComponent = (side, numb, duration) => {
  clearTimeout(timeoutInterval);
  timeoutInterval = setTimeout(() => {
    EventBus.$emit('switchComponent', {
      side,
      numb,
    });
  }, duration);
};

export default switchComponent;
