<template>
  <div class="event-id"
       :class="{'dark': dark}">
    <span>{{$store.getters.translate('event')}}</span>
    <span>{{eventId}}</span>
  </div>
</template>

<script>
export default {
  name: 'EventId',
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.event-id {
  color: rgba(243, 243, 243, .6);
  font-size: 14px;
  text-align: center;
  @media (min-width: 1920px) {
    font-size: 18px;
  }
  &.dark {
    color: #0B0B0B;
  }
  > span {
    &:last-child {
      padding-left: 3px;
    }
  }
}
</style>
