<template>
  <div class="event-id-time"
       :class="{'is-first': isFirst, 'no-others': !others}">
    <EventId :event-id="eventId"
             :dark="!others"/>
    <div class="time">{{getTime}}</div>
  </div>
</template>

<script>
import { formatDate } from '@/utility';
import EventId from '@/components/Retail/EventId';

export default {
  name: 'EventIdTime',
  components: { EventId },
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
    others: {
      type: Boolean,
      default: true,
    },
    isFirst: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getTime() {
      return formatDate(this.time, 'HH:mm', this.$store.getters.timeZone);
    },
  },
};
</script>

<style lang="scss" scoped>
.event-id-time {
  align-items: center;
  background: rgba(79, 79, 79, 0.4);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: center;
  margin-top: 8px;
  @media (min-width: 1920px) {
    height: 72px;
    &:not(&.is-first) {
      margin-top: 16px;
    }
  }
  &.no-others {
    background: rgba(255, 255, 255, 0.5);
    .event-id {
      color: #0B0B0B;
    }
    .time {
      color: #121212;
    }
  }
  .event-id {
    color: rgba(243, 243, 243, .6);
    font-size: 14px;
    @media (min-width: 1920px) {
      font-size: 18px;
    }
    > span {
      &:last-child {
        padding-left: 3px;
      }
    }
  }
  .time {
    color: #F3F3F3;
    font-size: 16px;
    font-weight: bold;
    @media (min-width: 1920px) {
      font-size: 24px;
    }
  }
}
</style>
