<template>
  <div class="flag-category-tournament"
       :class="{'no-default-font': fontSizeNotDefault}">
    <div class="flag-wrapper">
      <img :src="require(`../../assets/images/flags/${event.category.name}.png`)"
           :alt="event.category.isoCode"
           :style="flagSize || getFlagSize">
    </div>
    <div class="titles">
      <div class="category-title">
        {{ event.category.shortName.toUpperCase() }}
      </div>
      <div class="tournament-title">
        <NewName :name="event.tournament.shortName"
                 :searched-value="event.category.shortName" />
      </div>
    </div>
  </div>
</template>

<script>
import NewName from '@/components/Retail/NewName';
import { mapGetters } from 'vuex';

export default {
  name: 'FlagCategoryTournament',
  components: { NewName },
  props: {
    event: {
      type: Object,
      required: true,
    },
    flagSize: {
      type: Object,
    },
    isRowView: {
      type: Boolean,
      default: false,
    },
    fontSizeNotDefault: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'screenSize',
    ]),
    getFlagSize() {
      return {
        width: (this.screenSize === 1920 ? '105px' : '75px'),
        height: (this.screenSize === 1920 ? '75px' : '50px'),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.flag-category-tournament {
  align-items: center;
  display: flex;
  .flag-wrapper {
    margin-right: 9px;
    img {
      display: block;
    }
  }
  .category-title {
    color: #F3F3F3;
    font-size: 24px;
    font-weight: 700;
    @media (min-width: 1920px) {
      font-size: 36px;
    }
  }
  .tournament-title {
    font-size: 16px;
    @media (min-width: 1920px) {
      font-size: 24px;
    }
  }
  &.no-default-font {
    flex-direction: column;
    .category-title {
      font-size: 48px;
      margin-top: 16px;
      @media (min-width: 1920px) {
        font-size: 72px;
        margin-top: 24px;
      }
    }
    .tournament-title {
      font-size: 32px;
      @media (min-width: 1920px) {
        font-size: 48px;
      }
    }
  }
  &.draw {
    .titles {
      display: flex;
      .category-title {
        font-size: 16px;
        padding-right: 8px;
        text-transform: capitalize;
      }
      @media (min-width: 1920px) {
        .category-title,
        .tournament-title {
          font-size: 24px;
        }
      }
    }
  }
}

</style>
